<template>
  <div>
    <v-btn right dark color="primary" @click="addSection">
      <v-icon>mdi-plus</v-icon>
      <span>Add Section</span>
    </v-btn>
    <v-simple-table dense >
      <template v-slot:default>
        <thead v-if="section.length !== 0">
          <tr>
            <th>#</th>
            <th>Section Code</th>
            <th>Section Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(section, section_key) in section"
            :key="section.section_key"
          >
            <td>{{ section_key + 1 }}</td>
            <td >
              <v-text-field v-model="section.section_code" maxlength=3>
              </v-text-field>
            </td>
            <td width="70%">
              <v-text-field v-model="section.section_name">
              </v-text-field>
            </td>
            <td>
              <v-btn icon>
                <v-icon small color="red" @click="deleteSection(section_key)">delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		section: {
			type: Array
		},
		deleted: {
			type: Array
		}
	},
	watch: {
		section: {
			handler(val) {
				this.$emit('update:section', val);
			}
		},
		deleted: {
			handler(val){
				this.$emit('update:deleted', val);
			}
		}
	},
	mounted(){
	},
	computed: {
		...mapGetters({
			editedItem: 'dept/editedItem'
		}),
	},
	methods: {
		addSection() {
			const newSection = {section_code: '', section_name: '', section_id: ''};
			this.section.push(newSection);
		},
		deleteSection(key) {
			this.section.filter((detail, index) => {
				if (index === key) {
					this.section.splice(key, 1);
					if(detail.section_id != undefined || detail.section_id != ''){
						this.deleted.push({section_id: detail.section_id});
					}
				}
			});
		}
	}
};

</script>
