<template>
    <v-dialog v-model="dialog" persistent max-width="70%">
    <!-- <app-loader v-model="isLoading"></app-loader> -->
      <!-- <template v-slot:activator="{ on }">
        <v-btn outlined color="primary" @click="addSection">
          <v-icon dark>mdi-plus</v-icon>
          Add Section
        </v-btn>
      </template> -->
      <v-form
        ref="form"
        v-model="isvalid"
        lazy-validation
      >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.dept_code"
                    :items="depts.data"
                    data-vv-name="depts"
                    item-text="dept_desc"
                    item-value="dept_code"
                    label="Department Code"
                    readonly
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field 
                      v-model="editedItem.section_code"
                      :counter="3"
                      label="Section Code"
                      required
                      maxlength=3
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field 
                      v-model="editedItem.section_name"
                      :counter="250"
                      label="Section Name"
                      required
                      maxlength=250
                  ></v-text-field>
                </v-flex>
          </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
          <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      
      </v-card>
    </v-form>
  </v-dialog>
        
</template>

<script>
import { mapGetters } from 'vuex';
// import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	// props: {
	// 	dept_code,
	// },
	components: {
		// 'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: false,
		section_codeRules: [
			v => !!v || 'Section Code is required',
			v => (v && v.length <= 3) || 'Section Code must be 3 characters or less',
		],
		dept_codeRules: [
			v => !!v || 'Department Code is required',
			v => (v && v.length <= 3) || 'Department Code must be 3 characters or less.',
		],
		section_nameRules: [
			v => !!v || 'Section Name is required',
			v => (v && v.length <= 250) || 'Section Name must be 250 characters or less',
		],
	}),
	computed: {
		...mapGetters({
			sections: 'section/sections',
			depts: 'dept/depts',
			editedItem: 'section/editedItem',
			defaultItem: 'section/defaultItem',
			dialog: 'section/dialog',
			editedIndex: 'section/editedIndex',
			formTitle: 'section/formTitle',
			isLoading: 'section/isLoading',
			valid: 'section/valid',
		})
	}, 
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('section/setValid',true);
				this.$store.dispatch('section/setDialog',false);
				this.isvalid = false;
			}, 300);
		},
		async save () {
			this.$store.dispatch('section/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
        
			if(this.valid){
				this.$store.dispatch('section/setisLoading',true);
				if (this.editedItem.id !== '') {
					await this.$store.dispatch('section/updateSection',this.editedItem);
				} else {
					await this.$store.dispatch('section/saveSection',this.editedItem);
				}
				//this.close();
			}
		}
	}
};
</script>