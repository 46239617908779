<template>
    <v-dialog v-model="dialog" persistent max-width="60%" scrollable>
      <!-- <app-loader v-model="isLoading"></app-loader> -->
      <template v-slot:activator="{ on }">
        <app-add-button v-bind:module="'dept'"></app-add-button>
      </template>
          <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }} </v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
            <v-container grid-list-md>
            <v-layout wrap>
              <span class="title blue--text text-darken-2">Department Information</span> 
            </v-layout>
            <v-layout wrap>
                <v-flex xs12 sm6 md6>
                <v-text-field 
                    v-model="editedItem.dept_code"
                    :counter="3"
                    :rules="dept_codeRules"
                    label="Department Code"
                    required
                    maxlength=3
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                <v-text-field 
                    v-model="editedItem.dept_abbreviation"
                    :counter="4"
                    :rules="dept_abbr_Rules"
                    label="Department Abbreviation"
                    required
                    maxlength=4
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                <v-text-field 
                    v-model="editedItem.dept_desc"
                    :counter="50"
                    :rules="dept_name_Rules"
                    label="Department Name"
                    required
                    maxlength=50
                ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.div_code"
                    :counter="3"
                    label="Division Code"
                    maxlength=3
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.org_level"
                    :counter="3"
                    label="Organization Level"
                    maxlength=3
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.group_id"
                    :counter="4"
                    label="Group ID"
                    maxlength=4
                ></v-text-field>
                </v-flex> -->
                <v-flex xs12 sm6 md6>
                <v-select
                    v-model="editedItem.dept_status"
                    :items="deptStatus"
                    label="Status"
                    data-vv-name="deptStatus"
                    required
                ></v-select>
                </v-flex>
            </v-layout>
            <v-layout wrap>
              <span class="title blue--text text-darken-2">Sections</span> 
            </v-layout>
            <v-layout wrap>
              <v-flex md12 sm12>
              <add-section ref="sections" :section.sync="editedItem.sections" :deleted.sync="deleted"></add-section>
              </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
              <v-btn color="red darken-1"  text @click="close">Cancel</v-btn>
            </v-card-actions>

          </v-card>
          </v-form>
    
          <v-snackbar
            v-model="snackbar"
            right
          >
            {{ error_message }}
            <v-btn
              color="pink"
              text
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </v-snackbar>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';
import addSection from './addSection.vue';

export default {
	components:{
		'app-add-button': AppAddButtonVue,
		'add-section': addSection
	},
	data: () => ({
		snackbar: false,
		error_message: '',
		hasError: false,
		isvalid: false,
		dept_codeRules: [
			v => !!v || 'Department Code is required',
			v => (v && v.length <= 3) || 'Code must be 3 characters or less.',
		],
		dept_abbr_Rules: [
			v => !!v || 'Department Abbreviation is required',
			v => (v && v.length <= 4) || 'Department Abbreviation must be 4 characters or less',
		],
		dept_name_Rules: [
			v => !!v || 'Department Name is required',
			v => (v && v.length <= 50) || 'Department Name must be 50 characters or less',
		],
		sections: [],
		deleted: [],
	}),

	computed: {
		...mapGetters({
			depts: 'dept/depts',
			deptStatus: 'dept/deptStatus',
			editedItem: 'dept/editedItem',
			defaultItem: 'dept/defaultItem',
			dialog: 'dept/dialog',
			editedIndex: 'dept/editedIndex',
			formTitle: 'dept/formTitle',
			isLoading: 'dept/isLoading',
			valid: 'dept/valid',
			error: 'dept/error',
			headers: 'section/headers',
			currUser: 'auth/currUser'
		}),
	}, 
	mounted () {
		if(this.formTitle == 'New Entry'){
			this.$refs.form.reset();
			this.$refs.form.resetValidation();
			this.$store.dispatch('dept/setValid',true);
			this.$store.dispatch('dept/setDialog',false);
			this.$store.dispatch('dept/setEditedIndex', -1);
		}
	},
	methods: {
		validate () {
			if (this.$refs.form.validate()) {
				this.snackbar = true;
			}
		},
		reset () {
			this.$refs.form.reset();
		},
		resetValidation () {
			this.$refs.form.resetValidation();
		},
		newEntry(){
			this.$store.dispatch('dept/setDialog',true);
			this.$store.dispatch('dept/setformTitle','New Entry');
			this.$store.dispatch('dept/setValid',false);
		},
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('dept/setValid',true);
				this.$store.dispatch('dept/setDialog',false);
				this.$store.dispatch('dept/setEditedIndex', -1);
				this.editedItem.sections = [];
				this.deleted = [];
				this.isvalid = false;
			}, 2000);
		},
      
		async save () {
			this.hasError = false;
			this.$store.dispatch('dept/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			let updated = [];
			let added = [];
			if(this.valid){
				this.$store.dispatch('dept/setisLoading',true);
				if(this.editedItem.sections){
					this.editedItem.sections.map(details => {
						if(!details.section_code || !details.section_name){
							this.hasError = true;
						}
					});
				}
				this.editedItem.sections.map(details => {
					details.dept_code = this.editedItem.dept_code;
					details.edit_by = this.currUser.id;
					if(details.section_id != ''){
						updated.push(details);
					}else{
						added.push(details);
					}
				});
				this.editedItem.sections = added;
				this.editedItem.edit_by = this.currUser.id;
				this.editedItem.updated = updated;
				this.editedItem.deleted = this.deleted;
				delete this.editedItem['updated_at'];
				delete this.editedItem['created_at'];
				if(!this.hasError){
					if (this.editedIndex > -1) {
						await this.$store.dispatch('dept/updateDept',this.editedItem);
					} else {
						await this.$store.dispatch('dept/saveDept',this.editedItem);
					}
					setTimeout(() => {
						this.close();
					}, 3000);
				} else {
					this.snackbar = true;
					this.error_message = 'Please complete the details for Section.';
				}
			}
		},
	}
};
</script>