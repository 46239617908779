<template>
	<div class="home">
		<table-dept></table-dept>
	</div>
</template>

<script>
import tableDept from '@/components/pages/maint/department/tableDepartment.vue';

export default {
	components: {
		'table-dept': tableDept,
	}
};
</script>

<style>

</style>
