<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="depts.data"
			sort-by="dept_code"
			class="elevation-1"
			dense
			:search="search"
			:expanded.sync="expanded"
			show-expand
			hide-default-footer
			:items-per-page="1000"
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-toolbar-title>Departments</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field
					v-model="search"
					append-icon="search"
					label="Search"
					single-line
					hide-details
					></v-text-field>
					<vue-excel-xlsx
						:data="depts.data"
						:columns="columnHeaders"
						:file-name="module_name"
						>
						<v-btn icon alt class="mt-2" :color="depts.data.length=== 0 ? 'gray' : 'primary'" :disabled="depts.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
					</vue-excel-xlsx>
				</v-toolbar>
			</template>
			<template v-slot:expanded-item="{ item }">
				<td :colspan="headers.length">
					<v-simple-table>
						<template v-slot:default>
							<thead v-if="item.sections.length!= 0">
								<tr>
									<th>Section ID</th>
									<th>Sections</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="sec in item.sections" :key="sec.id">
									<td>{{sec.section_id}}</td>
									<td>{{sec.section_code}} - {{sec.section_name}}</td>
									<td>
										<v-icon color="primary" small class="mr-2" @click="editItemSection(item, sec)">edit</v-icon>
										<v-icon color="red" small @click="deleteSection(item, sec)">delete</v-icon>
									</td>
								</tr>
								<tr v-if="item.sections.length== 0">No Section for this Department</tr>
								<v-btn outlined color="primary" @click="addSection(item)"><v-icon dark>mdi-plus</v-icon>Add Section</v-btn>
							</tbody>
						</template>
        </v-simple-table>
				</td>
			</template>
			<template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
		<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(depts.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(depts.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{depts.current_page}} ( {{depts.from}} - {{depts.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!depts.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!depts.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
		</v-data-table>
		<create-dept v-if="role_access > 1"></create-dept>
		<create-section></create-section>
	</div>
</template>

<script>
import createDept from './createDepartment.vue';
import createSection from '../section/createSection.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-dept': createDept,
		'create-section': createSection
	},
	data() {
		return {
			module_name: 'Departments',
			role_access: 1,
			expanded: [],
			search: '',
		};
	},
	computed: {
		...mapGetters({
			depts: 'dept/depts',
			headers: 'dept/headers',
			columnHeaders: 'dept/columnHeaders',
			editedItem: 'dept/editedItem',
			defaultItem: 'dept/defaultItem',
			dialog: 'dept/dialog',
			editedIndex: 'dept/editedIndex',
			isLoading: 'dept/isLoading',
			valid: 'dept/valid',
			currUser: 'auth/currUser'
		}),
	},
	watch: {
		depts: {
			handler(val) {
				val.data.map(det => {
					det.status = (det.dept_status === 1 ? 'Active' : 'Inactive');
				});
			},
			deep: true
		}
	},
	mounted () {
		this.$store.dispatch('dept/getDepts');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		async editItem (item) {
			this.$store.dispatch('dept/setEditedIndex',this.depts.data.indexOf(item));
			// this.$store.dispatch('dept/setEditedItems',Object.assign({}, item));
			await this.$store.dispatch('dept/getDeptDetails', item.id);
			this.$store.dispatch('dept/setDialog',true);
			this.$store.dispatch('dept/setValid',true);
			this.$store.dispatch('dept/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.dept_desc + '(' + item.dept_code + ')' + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('dept/setEditedIndex',this.depts.data.indexOf(item));
					this.$store.dispatch('dept/deleteDept',item);
				}
			});
		},
		addSection(item) {
			let payload = {
				id: '',
				section_code: '',
				section_name: '',
				dept_code: item.dept_code,
				dept_id: item.id,
				edit_by: this.currUser.id
			};
			this.$store.dispatch('dept/setEditedIndex',this.depts.data.indexOf(item));
			this.$store.dispatch('section/setDialog',true);
			this.$store.dispatch('section/setformTitle','New Entry');
			this.$store.commit('section/SET_EDITED_ITEM', payload);
			this.$store.dispatch('section/setValid',false);
		},
		editItemSection(item, section){
			let id = '';
			id = section.section_id;
			if(section.section_id == undefined){
				id = section.id;
			}
			let payload = {
				id: id,
				section_code: section.section_code,
				section_name: section.section_name,
				dept_code: section.dept_code,
				dept_id: section.dept_id,
				edit_by: this.currUser.id
			};
			this.$store.dispatch('dept/setEditedIndex',this.depts.data.indexOf(item));
			this.$store.commit('dept/SET_SECTION_EDITEDINDEX', item.sections.indexOf(section), {root: true});
			this.$store.dispatch('section/setDialog',true);
			this.$store.dispatch('section/setformTitle','Edit Entry');
			this.$store.commit('section/SET_EDITED_ITEM', payload);
			this.$store.dispatch('section/setValid',false);
		},
		deleteSection(item, section){
			let id = '';
			id = section.section_id;
			if(section.section_id == undefined){
				id = section.id;
			}
			let payload = {
				section_id: id,
				section_code: section.section_code,
				section_name: section.section_name,
				dept_code: section.dept_code,
				dept_id: section.dept_id,
			};
			this.$store.commit('dept/SET_SECTION_EDITEDINDEX', item.sections.indexOf(section), {root: true});
			this.$store.dispatch('dept/setEditedIndex',this.depts.data.indexOf(item));
			this.$swal.fire({
				title: 'Delete!',
				text: section.section_code + '(' + section.section_name + ')' + '? You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('section/deleteSection',payload);
				}
			});
		},
		changeNextData() {
			var nextPage = this.depts.current_page + 1;
			this.$store.dispatch('dept/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.depts.current_page - 1;
			this.$store.dispatch('dept/getDataPages', prevPage);
		}
	}
};
</script>

<style scoped>
</style>